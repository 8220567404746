/* --------------------------------
   pagination
-------------------------------- */

.pagination
{
	line-height: 0;
	font-size: 0;
	letter-spacing: -1px;
	margin-top: 50px;

	li
	{
		display: inline-block;
		vertical-align: top;
		line-height: 1;
		font-size: rem-calc(14px);
		letter-spacing: 0;
		margin-left: 5px;

		&:hover,
		&:focus
		{
			a
			{
				border-color: #efefef;
				color: $primary-color;
			}
		}

		&.prev { padding-right: 5px; }
		&.next { padding-left: 10px; }

		&.active
		{
			a
			{
				cursor: default;
				background-color: $primary-color;
				border-color: $primary-color;
				color: $white-color;
			}
		}
	}

	a
	{
		display: inherit;
		width: 35px;
		height: 35px;
		line-height: 31px;
		text-align: center;
		color: #252525;
		border: 2px solid transparent;
		@include transition(
			background-color 0.3s ease-in-out,
			border-color 0.3s ease-in-out,
			color 0.3s ease-in-out
		);
	}
}

@include min-screen($sm-width)
{

}

@include min-screen($md-width)
{

}

@include min-screen($lg-width)
{

}

@include min-screen($xl-width)
{

}